import { createUseStyles } from 'react-jss';

interface StyleProps {
  columnsValue: number;
}

export const useStyles = createUseStyles({
  blockWrapper:({ columnsValue }: StyleProps) => ({
    margin: 10,
    width: `calc(100% / ${columnsValue || 4} - 20px)`,
    position: 'relative',
    borderRadius: 10,
    aspectRatio: 1,
    "& img": {
      borderRadius: 10,
    }
  }),
  addedToCart: {
    width: 22,
    height: 24,
    backgroundColor: "green",
    color: "white",
    textAlign: 'center',
    padding:' 0 0px',
    borderRadius: 5,
  },
  itemNav:{
    position: "absolute",
    left: 5,
    top: 5,
    height: "auto",
    width: '100%'
  },
  itemNavBottom:{
    position: "absolute",
    left: 5,
    bottom: 5,
    width: "100%",
    display: 'flex',
    justifyContent: 'flex-end'
  },
  popUp:{
    position:"absolute",
    width: '100%',
    height: '100%',
    display: "flex",
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 10,
    left: 0,
    top: 0,
    backgroundColor: 'rgba(0,0,0,0.67)'
  },
  popUpWrap:{
    width: '80%',
    maxHeight: "80%",
    height: '80%',
    backgroundColor: "white",
    borderRadius: 10,
    left: 0,
    right: 0,
    padding: 10,
    position:"relative",
  },
  contentWrapper: {
    height: "85%",
    maxHeight: "85%",
    overflow:"scroll",
    width: '100%'
  },
  popUpBox:{
    display: "flex",
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 10,
    "& img":{
      maxWidth: '33%',
      height: 'auto',
      maxHeight: 400,
    }

  },
  popUpClose:{
    position: "absolute",
    width: '35px',
    height: '35px',
    right: -15,
    top:-15,
    zIndex: 11,
    backgroundColor: "white",
    cursor: "pointer",
    borderRadius: 20,
    paddingTop: '4px',
    fontWeight: 900,
    textAlign: 'center',
    border: '1px solid black',
  },

  ///NEW ONE
  editButton: {
    padding:[8, 20],
    width: 'fit-content',
    backgroundColor: '#047d95',
    color: 'white',
    cursor:'pointer',
    fontWeight: 600,
    borderRadius: 5,
    height: "40px",
    margin: '10px 10px',
    '&:hover': {
      backgroundColor: '#005669',
    }
  },
  cancelButton: {
    padding:[8, 30],
    width: 'fit-content',
    backgroundColor: '#ff0000',
    color: 'white',
    cursor:'pointer',
    fontWeight: 600,
    borderRadius: 5,
    height: "40px",
    margin: '10px 10px',
    '&:hover': {
      backgroundColor: '#c00707',
    }
  },
  holdButton:{
    padding:[8, 30],
    width: 'fit-content',
    backgroundColor: '#40f8f8',
    color: 'black',
    cursor:'pointer',
    fontWeight: 600,
    borderRadius: 5,
    height: "40px",
    margin: '10px 10px',
    '&:hover': {
      backgroundColor: '#2eafaf',
    }
  }
});
