import * as React from 'react';
import { useNavigate } from 'react-router';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import FormControlLabel from '@mui/material/FormControlLabel';
import TableHead from '@mui/material/TableHead';
import Switch from '@mui/material/Switch';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

import { useStyles } from './styles';
import {useState} from "react";



const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: '#0000000a',
    },
}));


const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        maxWidth: 400,
        border: '1px solid #dadde9',
    },
}));



export default function Analutics3dTable(props: any) {
    const { events, paging, pagingUpdate } = props;
    const [showImage, setShowImage] = useState(false);
    const [showMockup, setShowMockup] = useState(false);
    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: '65vh', position: 'relative'}}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell
                                align={'center'}
                                style={{ minWidth: 100 }}
                            >
                              Event ID
                            </TableCell>
                            <TableCell
                                align={'center'}
                                style={{ minWidth: 100 }}
                            >
                                Customer ID
                            </TableCell>
                            <TableCell
                                align={'center'}
                                style={{ minWidth: 100 }}
                            >
                               Session ID
                            </TableCell>
                            <TableCell
                                align={'center'}
                                style={{ minWidth: 100 }}
                            >
                                End Status
                            </TableCell>
                            <TableCell
                                align={'center'}
                                style={{ minWidth: 100 }}
                            >
                                Process Duration
                            </TableCell>
                            <TableCell
                                align={'center'}
                                style={{ minWidth: 100 }}
                            >
                                Mockup Duration
                            </TableCell>
                            <TableCell
                                align={'center'}
                                style={{ minWidth: 100 }}
                            >
                                Saving Duration
                            </TableCell>
                            <TableCell
                                align={'center'}
                                style={{ minWidth: 100 }}
                            >
                                InitMockupDelay
                            </TableCell>
                            <TableCell
                                align={'center'}
                                style={{ minWidth: 100 }}
                            >
                                Image type
                            </TableCell>
                            <TableCell
                                align={'center'}
                                style={{ minWidth: 100 }}
                            >
                                <FormControlLabel
                                    control={
                                            <Switch
                                                size={'small'}
                                                checked={showMockup}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                    setShowMockup(event.target.checked);
                                                }}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                                name="showMockup"
                                            />
                                    }
                                    label="Mockup"
                                />
                            </TableCell>
                            <TableCell
                                align={'center'}
                                style={{ minWidth: 100 }}
                            >
                                <FormControlLabel
                                    control={
                                        <Switch
                                            size={'small'}
                                            checked={showImage}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                setShowImage(event.target.checked);
                                            }}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            name="showImage"
                                        />
                                    }
                                    label="Image"
                                />
                            </TableCell>
                            <TableCell
                                align={'center'}
                                style={{ minWidth: 100 }}
                            >
                                Save URL
                            </TableCell>
                            <TableCell
                                align={'center'}
                                style={{ minWidth: 100 }}
                            >
                                Add to Cart
                            </TableCell>
                            <TableCell
                                align={'center'}
                                style={{ minWidth: 100 }}
                            >
                                Browser
                            </TableCell>
                            <TableCell
                                align={'center'}
                                style={{ minWidth: 100 }}
                            >
                                Platform
                            </TableCell>
                            <TableCell
                                align={'center'}
                                style={{ minWidth: 100 }}
                            >
                                Device
                            </TableCell>
                            <TableCell
                                align={'center'}
                                style={{ minWidth: 100 }}
                            >
                                ErrorMessage
                            </TableCell>
                            <TableCell
                                align={'center'}
                                style={{ minWidth: 100 }}
                            >
                                ErrorMessageDetailed
                            </TableCell>
                            <TableCell
                                align={'center'}
                                style={{ minWidth: 100 }}
                            >
                                Region
                            </TableCell>
                            <TableCell
                                align={'center'}
                                style={{ minWidth: 100 }}
                            >
                                Country
                            </TableCell>
                            <TableCell
                                align={'center'}
                                style={{ minWidth: 100 }}
                            >
                               City
                            </TableCell>
                            <TableCell
                                align={'center'}
                                style={{ minWidth: 100 }}
                            >
                                IP address
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {events
                            .map((event) => {
                                return (
                                    <StyledTableRow tabIndex={-1} key={event.id}>
                                        <TableCell
                                            align={'center'}
                                            style={{ minWidth: 100 }}
                                        >
                                            {event.id}
                                        </TableCell>
                                        <TableCell
                                            align={'center'}
                                            style={{ minWidth: 100 }}
                                        >
                                            {event.customerId}
                                        </TableCell>
                                        <TableCell
                                            align={'center'}
                                            style={{ minWidth: 100 }}
                                        >
                                            {event.currentSessionId}
                                        </TableCell>
                                        <TableCell
                                            align={'center'}
                                            style={{ minWidth: 100 }}
                                        >
                                            {event.endStatus}
                                        </TableCell>
                                        <TableCell
                                            align={'center'}
                                            style={{ minWidth: 100 }}
                                        >
                                            {event.processDuration}
                                        </TableCell>
                                        <TableCell
                                            align={'center'}
                                            style={{ minWidth: 100 }}
                                        >
                                            {event.mockupDuration}
                                        </TableCell>
                                        <TableCell
                                            align={'center'}
                                            style={{ minWidth: 100 }}
                                        >
                                            {event.savingDuration}
                                        </TableCell>
                                        <TableCell
                                            align={'center'}
                                            style={{ minWidth: 100 }}
                                        >
                                            {event.initMockupDelay}
                                        </TableCell>
                                        <TableCell
                                            align={'center'}
                                            style={{ minWidth: 100 }}
                                        >
                                            {event.imageType}
                                        </TableCell>
                                        <TableCell
                                            align={'center'}
                                            style={!showMockup ?{ minWidth: 170 }: {minWidth: 380, minHeight: 400}}
                                        >
                                            {!showMockup ?
                                                <HtmlTooltip
                                                    title={
                                                        <React.Fragment>
                                                            <img style={{
                                                                maxHeight: '400px',
                                                                maxWidth: '380px',
                                                                width: 'auto'
                                                            }} src={event.mockupUrl}/>
                                                        </React.Fragment>
                                                    }
                                                >
                                                    <a style={{maxHeight: '65px', width: '100%'}}
                                                       href={event.mockupUrl}> Mockup URL</a>
                                                </HtmlTooltip>
                                                : <img loading="lazy"
                                                       style={{maxHeight: '400px', maxWidth: '380px', width: 'auto'}}
                                                       src={event.mockupUrl}/>
                                            }
                                        </TableCell>
                                        <TableCell
                                            align={'center'}
                                            // style={{ minWidth: 170 }}
                                            style={!showImage ?{ minWidth: 170 }: {minWidth: 380, minHeight: 400}}
                                        >
                                            {!showImage ?
                                                <HtmlTooltip
                                                    title={
                                                        <React.Fragment>
                                                            <img style={{
                                                                maxHeight: '400px',
                                                                maxWidth: '380px',
                                                                width: 'auto'
                                                            }} src={event.fileUrl}/>
                                                        </React.Fragment>
                                                    }
                                                >
                                                    <a style={{maxHeight: '65px', width: '100%'}}
                                                       href={event.fileUrl}> Image URL </a>
                                                </HtmlTooltip>
                                                : <img loading="lazy"
                                                       style={{maxHeight: '400px', maxWidth: '380px', width: 'auto'}}
                                                       src={event.fileUrl}/>
                                            }
                                        </TableCell>
                                        <TableCell
                                            align={'center'}
                                            style={{ minWidth: 100 }}
                                        >
                                            <HtmlTooltip
                                                title={
                                                    <React.Fragment>
                                                        <img style={{maxHeight:'400px',maxWidth: '380px', width: 'auto'}} src={event.saveUrl} />
                                                    </React.Fragment>
                                                }
                                            >
                                                <a style={{maxHeight:'65px', width: '100%'}} href={event.saveUrl}> Save URL</a>
                                            </HtmlTooltip>
                                        </TableCell>
                                        <TableCell
                                            align={'center'}
                                            style={{ minWidth: 100 }}
                                        >
                                            {event.toCart ? event.toCart : 'not added' }
                                        </TableCell>
                                        <TableCell
                                            align={'center'}
                                            style={{ minWidth: 100 }}
                                        >
                                            {event.browser}
                                        </TableCell>
                                        <TableCell
                                            align={'center'}
                                            style={{ minWidth: 100 }}
                                        >
                                            {event.platform}/{event.os}
                                        </TableCell>
                                        <TableCell
                                            align={'center'}
                                            style={{ minWidth: 100 }}
                                        >
                                            {event.deviceType}/{event.device}
                                        </TableCell>
                                        <TableCell
                                            align={'center'}
                                            style={{ minWidth: 100, maxWidth: '200px', overflow: 'scroll' }}
                                        >
                                            {event.errorMessage != '' ? `${event.errorMessage}`: 'none'}
                                        </TableCell>
                                        <TableCell
                                            align={'center'}
                                            style={{ minWidth: 100 , maxWidth: '200px', overflow: 'scroll'}}
                                        >
                                            {event.errorMessageDetailed != '' ? `${event.errorMessageDetailed}` : 'none'}
                                        </TableCell>
                                        <TableCell
                                            align={'center'}
                                            style={{ minWidth: 100 }}
                                        >
                                            {event.region}
                                        </TableCell>
                                        <TableCell
                                            align={'center'}
                                            style={{ minWidth: 100 }}
                                        >
                                            {event.country}
                                        </TableCell>
                                        <TableCell
                                            align={'center'}
                                            style={{ minWidth: 100 }}
                                        >
                                            {event.city}
                                        </TableCell>
                                        <TableCell
                                            align={'center'}
                                            style={{ minWidth: 100 }}
                                        >
                                            {event.ip}
                                        </TableCell>
                                    </StyledTableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            {/*<TablePagination*/}
            {/*    rowsPerPageOptions={[25, 50, 100]}*/}
            {/*    component="div"*/}
            {/*    count={paging.total}*/}
            {/*    rowsPerPage={paging.perPage}*/}
            {/*    page={paging.page-1}*/}
            {/*    onPageChange={handleChangePage}*/}
            {/*    onRowsPerPageChange={handleChangeRowsPerPage}*/}
            {/*/>*/}
        </Paper>
    );
}