import * as React from 'react';
import { useNavigate } from 'react-router';
import {PRINTFULL_ID, FREEPRINTS_ID, INK_ID} from '../../constants'
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

import { useStyles } from './styles';



const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: '#0000000a',
    },
}));


export default function OrdersTable(props: any) {
    const { orders, paging, pagingUpdate } = props;


    const classes = useStyles();
    const navigator = useNavigate();

    const handleChangePage = (event: unknown, newPage: number) => {
        pagingUpdate({...paging, page: newPage+1, perPage: paging.perPage})
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        pagingUpdate({...paging, page: 1, perPage: +event.target.value})
    };

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: '65vh', position: 'relative'}}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell
                                align={'center'}
                                style={{ minWidth: 100 }}
                            >
                              Date
                            </TableCell>
                            <TableCell
                                align={'center'}
                                style={{ minWidth: 100 }}
                            >
                                Printful
                            </TableCell>
                            <TableCell
                                align={'center'}
                                style={{ minWidth: 100 }}
                            >
                               Freeprints
                            </TableCell>
                            <TableCell
                                align={'center'}
                                style={{ minWidth: 100 }}
                            >
                                INK
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {orders
                            .map((order) => {
                                return (
                                    <StyledTableRow tabIndex={-1} key={order.orderId}>
                                        <TableCell
                                            align={'center'}
                                            style={{ minWidth: 100 }}
                                        >
                                            {order?.date}
                                        </TableCell>
                                        <TableCell
                                            align={'center'}
                                            style={{ minWidth: 100 }}
                                        >
                                            {order?.clientData.filter(item => item.clientId === PRINTFULL_ID)[0]?.quantity || 0}
                                        </TableCell>
                                        <TableCell
                                            align={'center'}
                                            style={{ minWidth: 100 }}
                                        >
                                            {order?.clientData.filter(item => item.clientId === FREEPRINTS_ID)[0]?.quantity || 0}
                                        </TableCell>
                                        <TableCell
                                            align={'center'}
                                            style={{ minWidth: 100 }}
                                        >
                                            {order?.clientData.filter(item => item.clientId === INK_ID)[0]?.quantity || 0}
                                        </TableCell>
                                    </StyledTableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}