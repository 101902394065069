import React, {useState, useEffect, Fragment} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Bar, Pie, Doughnut } from 'react-chartjs-2';
import { Chart, CategoryScale, LinearScale, BarElement, ArcElement, Tooltip, Legend, Title } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useStyles } from './styles';


/** Components */
import Skeleton from '@mui/material/Skeleton';
import PageContainer from '../../components/PageContainer';
import StoriesTable from '../../components/StoriesTable';
import StoriesFilters from '../../components/StoriesFilters';
/** Selectors */
import { Analytics3d }  from '../../store/slices'
import {useParams, useSearchParams} from "react-router-dom";
import {useNavigate} from "react-router";
import {IOneOrder, IOrder, IStore} from "../../store/types";
import OrdersTable from "../../components/OrdersTable";
import OrdersFilters from "../../components/OrdersFilters";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import OrdersReport from "../../components/OrdersReport";
import OrdersProduction from "../../components/OrdersProduction";
import Analutics3dTable from "../../components/Analytics3dTable";
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";

const DEFAULT_PAGING_STATE = {
  perPage: 25,
  page: 1,
  total: 0,
  sort: 'createdAt',
  sortDirection:'DESC'
};

const columnsValues = [
  2,3,4,5,6
]
Chart.register(CategoryScale, LinearScale, BarElement, ArcElement, Tooltip, Legend,  Title, ChartDataLabels);
const MockupsPage = () => {
  const {
    analytics3d,
    status,
    queryParams
  } = useSelector<IStore, { analytics3d: any, status: string , queryParams: any}>(state => ({
    analytics3d: state.analytics3d.data,
    status: state.analytics3d.status,
    queryParams: state.analytics3d.queryParams
  }))
  const { id } = useParams();
  const dispatch = useDispatch();
  const [isReportPopUp, setReportPopUp] = useState(false);
  const [isProductionPopUp, setProductionPopUp] = useState(false);
  const navigator = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [columnsValue, setColumnsValue] = React.useState(4);
  const [uploadsValue, setUploadsValue] = React.useState(500);
  const classes = useStyles({columnsValue});
  const [popup, setPopup] = useState<JSX.Element | null>(null);

  const handleChangeColumns = (event: React.SyntheticEvent, newValue: number) => {
    setColumnsValue(newValue);
  };

  const loadOrders = (queryParams) => {
      console.log('params', queryParams)
    dispatch<any>(Analytics3d.getAnalytics3d(queryParams));
  };

  const showPopup = (data) => {
    const closePopup = () => setPopup(null);
    setPopup(
        <div className={classes.popUp}>
          <div className={classes.popUpWrap}>
            <div onClick={()=>closePopup()} className={classes.popUpClose}>X</div>
            <h2 style={{textAlign: 'center'}}>Customer Upload History</h2>
            <div className={classes.contentWrapper}>
            {data.reverse().map((element, index) => {
            return(
                <div className={classes.popUpBox}>
                  <div style={{width: '33%', textAlign: 'center'}}>
                    <div>Upload #<span>{index+1}</span></div>
                    <div>{new Date(element.processEnd).toLocaleString()}</div>
                    <div>Process Duration - <span>{element?.processDuration}s</span></div>
                    <div>Was added to Cart: <span>{element.toCart ? 'YES' : 'NO'}</span></div>
                  </div>
                  {element.mockupUrl ?
                      <img loading="lazy"
                          // style={{maxHeight: '100%', maxWidth: '100%', width: 'auto'}}
                           src={element.fileUrl}/>:
                      <div>NOT FOUND</div>
                  }
                  {element.mockupUrl ?
                      <img loading="lazy"
                          // style={{maxHeight: '100%', maxWidth: '100%', width: 'auto'}}
                           src={element.mockupUrl}/>:
                      <div>NOT FOUND</div>
                  }
                </div>
            )
          })}
            </div>
          </div>
        </div>
    );
  };

  useEffect(() => {
    console.log('update quantity');
    loadOrders({
      perPage: uploadsValue,
    });
  }, [uploadsValue])

  useEffect(() => {
    loadOrders({
      perPage: uploadsValue,
    });
  }, [])
  return (
    <PageContainer>
      <div style={{ display: 'flex', justifyContent: 'space-between'}}>
        <h2 style={{marginTop: 0, color:'#047d95', fontWeight: 600}}>3D-Mockups
        </h2>

      </div>
      {popup}
      <div style={{display: 'flex'}}>
        <FormControl sx={{ m: 1,  width: '15ch', marginLeft: '0' }} >
          <InputLabel id="demo-simple-select-helper-label">Columns</InputLabel>
          <Select
              id="demo-simple-select-helper"
              // defaultValue={queryParams?.orderStatusId ? queryParams?.orderStatusId : []}
              value={columnsValue}
              label="Columns"
              variant="outlined"
              name="orderStatusId"
              onChange={(event) =>{setColumnsValue(Number(event.target.value))}}
              disabled={status === 'loading'}
          >
            {columnsValues.map((order)=>
                <MenuItem key={order} value={order}>{order}
                  {/*<Checkbox checked={queryParams?.orderStatusId && queryParams?.orderStatusId.indexOf(order.value) > -1} />*/}
                  {/*<ListItemText primary={order.status} />*/}
                </MenuItem>
            )}
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1,  width: '15ch' }} >
          <InputLabel id="demo-simple-select-helper-label">Uploads quantity</InputLabel>
          <Select
              id="demo-simple-select-helper"
              // defaultValue={queryParams?.orderStatusId ? queryParams?.orderStatusId : []}
              value={uploadsValue}
              label="Uploads quantity"
              variant="outlined"
              name="orderStatusId"
              onChange={(event) =>{setUploadsValue(Number(event.target.value))}}
              disabled={status === 'loading'}
          >
            {[100, 500, 1000].map((order)=>
                <MenuItem key={order} value={order}>{order}
                  {/*<Checkbox checked={queryParams?.orderStatusId && queryParams?.orderStatusId.indexOf(order.value) > -1} />*/}
                  {/*<ListItemText primary={order.status} />*/}
                </MenuItem>
            )}
          </Select>
        </FormControl>
      </div>
      {(analytics3d && status !== 'loading') &&
      <Box sx={{ width: '100%', typography: 'body1', maxHeight: '80vh', overflow: 'scroll', border: '1px solid #dddddd', borderRadius: '10px' }}>
        <div style={{   display: 'flex',
                        flexWrap: 'wrap',
                        padding: '10px'}}>
          {analytics3d?.result &&
              analytics3d.result.map((event)=>{
                return(
                    <div className={classes.blockWrapper}>
                      {event?.mockupUrl ? <img loading="lazy"
                                                                            image-Data="mockup"
                                              style={{maxHeight: '100%', maxWidth: '100%', width: 'auto'}}
                                              src={event.mockupUrl}/>
                          : <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', flexDirection: "column"}}><div>Upload with issue</div>
                            <div>Event ID :</div>
                            <div>{event.id}</div>
                      </div>}
                      <div className={classes.itemNav}>
                        {event.toCart && <div className={classes.addedToCart}>✔</div>}

                      </div>
                      <div className={classes.itemNavBottom}>
                        {analytics3d.result.filter(item => item.customerId === event.customerId).length > 1 &&
                            <div className={classes.editButton}
                                 onClick={()=>showPopup([...analytics3d.result.filter(item => item.customerId === event.customerId)])}>History</div>}
                        {/*<div className={classes.holdButton}>Details</div>*/}
                      </div>
                    </div>
                )
              })
          }
        </div>
      </Box>
      }
      {analytics3d?.length < 1 && status !== 'loading' && <div>Don't found result</div>}
      {status === 'loading' &&
        <Skeleton animation="wave" width={'100%'} height={600} variant="rounded" />}
    </PageContainer>

  );
};

export default MockupsPage;
