import {API_URL, ORDER_API_URL} from '../constants'
import store from '../store';

import {logOut} from "./auth";


export const stringifyAnalytics3dParameters = (parameters) => {
    const query = [];
    try {
        Object.keys(parameters)
            .filter((key) => parameters[key])
            .forEach((key) => {
                switch (key) {
                    case 'page': { // @ts-ignore
                        query.push(`${key}=${parameters[key]}`);
                        break;
                    }
                    case 'search':{
                        if(parameters[key].length >= 3){
                            // @ts-ignore
                            query.push(`${key}=${parameters[key]}`);
                        }
                        break;
                    }
                    case 'isPublished': {
                        // @ts-ignore
                        query.push(`${key}=${parameters[key]}`);
                        break;
                    }
                    case 'perPage': {
                        // @ts-ignore
                        query.push(`limit=${parameters[key]}`);
                        break;
                    }
                    case 'sort': {
                        // @ts-ignore
                        query.push(`sort=${parameters[key]}`);
                        break;
                    }
                    case 'sortDirection': {
                        // @ts-ignore
                        query.push(`sortDirection=${parameters[key]}`);
                        break;
                    }
                    case 'minDate': {
                        // @ts-ignore
                        query.push(`createdAt_min=${parameters[key]}`);
                        break;
                    }
                    case 'maxDate':{
                        // @ts-ignore
                        query.push(`createdAt_max=${parameters[key]}`);
                        break;
                    }
                    case 'onHold':{

                        console.log(parameters[key]);
                        // @ts-ignore
                        query.push(`onHold=${parameters[key]}`);
                        break;
                    }
                    case 'orderStatusId':{
                        if(parameters[key].length > 0){
                            // @ts-ignore
                            query.push(`${key}=${parameters[key]}`);
                        }
                        break;
                    }
                    default: {
                        break;
                    }
                }
            });
    } catch (error) {
        console.warn('Looks like something wrong with fetch products url parameters');
    }
    console.log(`${query.join('&')}`)
    return `${query.join('&')}`;
};


export const getFullAnalytics3d = async (queryParams) => {
    const token = store.getState().auth.data.token;
    const res = await fetch(`https://ddi2zn6s0epbc.cloudfront.net/stat-prod/stat/m-event?${stringifyAnalytics3dParameters(queryParams)}`,{
        method: "GET",
        headers: {
            // "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            // "Authorization": `Bearer ${encodeURIComponent(token)}`
        },
    });
    if (!res.ok) {
        if(res.status === 401){
            await logOut();
            throw new Error('Unauthorized!');
        }
        throw new Error('Server Error!');
    }
    return await res.json();
    // return DampData;
};

// export const getOrderById = async (data) => {
//     const token = store.getState().auth.data.token;
//     const res = await fetch(`${ORDER_API_URL}/clients/${data.companyId}/orders/${data.id}`,{
//         method: "GET",
//         headers: {
//             "Content-Type": "application/json",
//             "Authorization": `Bearer ${encodeURIComponent(token)}`
//         },
//     });
//     if (!res.ok) {
//         if(res.status === 401){
//             store.dispatch(logOutUser())
//             throw new Error('Unauthorized!');
//         }
//         throw new Error('Server Error!');
//     }
//     return await res.json();
// };
// export const updateOrderById = async (id, data) => {
//     const token = store.getState().auth.data.token;
//     const res = await fetch(`${ORDER_API_URL}secure/orders/${id}`,{
//         method: "PUT",
//         headers: {
//             "Content-Type": "application/json",
//             "Authorization": `Bearer ${encodeURIComponent(token)}`
//         },
//         body: JSON.stringify({...data})
//     });
//     if (!res.ok) {
//         if(res.status === 401){
//             store.dispatch(logOutUser())
//             throw new Error('Unauthorized!');
//         }
//         throw new Error('Server Error!');
//     }
//     return await res.json();
// };
// export const createOrder = async (data) => {
//     const token = store.getState().auth.data.token;
//     const res = await fetch(`${API_URL}secure/orders`,{
//         method: "POST",
//         headers: {
//             "Content-Type": "application/json",
//             "Authorization": `Bearer ${encodeURIComponent(token)}`
//         },
//         body: JSON.stringify({...data})
//     });
//     if (!res.ok) {
//         if(res.status === 401){
//             store.dispatch(logOutUser())
//             throw new Error('Unauthorized!');
//         }
//         throw new Error('Server Error!');
//     }
//     return await res.json();
// };


// export const getReportModerator = async ({companyId, queryParams,  onSuccess}) => {
//     const token = store.getState().auth.data.token;
//     const res = await fetch(`${ORDER_API_URL}/clients/${companyId}/reports/orders/prinfile_v1`,{
//         method: "POST",
//         // mode: "no-cors", // no-cors, *cors, same-origin
//         headers: {
//             "Content-Type": "application/json",
//             // 'Content-Type': 'application/x-www-form-urlencoded',
//             // 'Access-Control-Allow-Origin': '*',
//             "Authorization": `Bearer ${encodeURIComponent(token)}`
//         },
//         body:JSON.stringify(queryParams),
//     });
//     if (!res.ok) {
//         if(res.status === 401){
//             store.dispatch(logOutUser())
//             throw new Error('Unauthorized!');
//         }
//         throw new Error('Server Error!');
//     }
//     const rest = await res.json();
//     if (onSuccess) {
//         onSuccess(rest);
//     }
//     return rest;
// };



